import {Component} from "react";
import {Card, Placeholder} from "react-bootstrap";
import doctorIcon from "../../views/ListOfDoctors/doctor-kid.svg";

export default class DoctorInfoPlaceholder extends Component {
    render() {
        return <div data-testid="placeholder">
            <Card style={{width: "18rem"}}>
                <Card.Img variant="top" src={doctorIcon}/>
                <Card.Body>
                    <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6}/>
                    </Placeholder>
                    <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={7}/> <Placeholder xs={4}/> <Placeholder xs={4}/>{" "}
                        <Placeholder xs={6}/> <Placeholder xs={8}/>
                    </Placeholder>
                    <Placeholder.Button variant="primary" xs={6}/>
                </Card.Body>
            </Card>
        </div>;
    }
}
