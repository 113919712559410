import firebaseInterface from "../../hailJesus/FirebaseInterface"
import {useEffect, useState} from "react";
import {User} from "../../modal/User";
import {pay, verify} from "../../hailJesus/RazorPayInterface";
import {Redirect, useParams} from "react-router-dom";
import {findDoctorWithId} from "../../services/listOfAllDoctors";
import {SignUpDoctorRequest} from "../DoctorSignUp/SignUpAsDoctor";
import loginSvg from "../HelloDoctor/Login.svg";
import cardLogo from "./card.svg";
import cardAnimation from "./card.json";
import Button from "react-bootstrap/Button";
import styles from "./style.module.css"
import {Player} from "@lottiefiles/react-lottie-player";

export function LoginAndPay() {

    const [user, setUser] = useState<User | undefined>(undefined);
    const {doctorId} = useParams<{ doctorId: string }>();
    const [userRequestInProgress, setUserRequestInProgress] = useState(false)
    const [paymentProcessing, setPaymentProcessing] = useState<boolean>(false);
    const [paymentSuccess, setPaymentSuccess] = useState<boolean | undefined>(false);
    const [verifyOrderProcessingCompleted, setVerifyOrderProcessingCompleted] = useState<boolean | undefined>(undefined);
    const [doctor, setDoctor] = useState<SignUpDoctorRequest | undefined>(undefined);

    function startPaymentProcess() {
        firebaseInterface.logPaymentInitiated();
        setPaymentProcessing(true)
        pay(99 * 100, function (_response) {
            setPaymentProcessing(false)
            setPaymentSuccess(true)
            firebaseInterface.logPaymentSuccess()
            if (user && doctor) {
                setVerifyOrderProcessingCompleted(false)
                verify({
                    doctorId: doctorId,
                    doctorName: doctor.name,
                    patientName: user.displayName,
                    patientId: user.userId,
                    razorpayPaymentId: _response.razorpay_payment_id,
                    razorpayOrderId: _response.razorpay_order_id,
                    razorpaySignature: _response.razorpay_signature
                }).then(() => {
                    firebaseInterface.logOrderVerifySuccess()
                    setVerifyOrderProcessingCompleted(true)
                })
            }
        }, function (_errorResponse) {

            setPaymentProcessing(false)
            setPaymentSuccess(false)
        }, () => {
            setPaymentProcessing(false);
            setPaymentSuccess(false);
        })
    }

    useEffect(() => {
        firebaseInterface.logPageView({
            page_title: "Payment page landing",
            page_location: "payment page landing for " + doctorId ,
            page_path: "pay/" + doctorId
        });
        setPaymentProcessing(false)
        setPaymentSuccess(undefined)

        findDoctorWithId(doctorId).then((doc) => {
            setDoctor(doc)
        })

        setUserRequestInProgress(true)
        return firebaseInterface.onAuthChanged((user) => {
            setUserRequestInProgress(false)
            if (user) {
                firebaseInterface.logUserSuccessfulAuthentication()
                setUser({
                    userId: user?.uid,
                    displayName: user.displayName!!
                })

                return;
            }

            setUser(undefined)
        })
    }, [doctorId])

    function login() {
        firebaseInterface.redirectForLogin()
    }

    return <>
        {user ?
            <>
                {
                    paymentProcessing ?
                        <div className="container h-100">
                            <div className="row align-items-center h-100">
                                <div className="row">
                                    <h2 className="display-6">Please wait.
                                        <small className="text-muted">Do not refresh</small>
                                    </h2>
                                </div>
                                <div className="row">
                                    <Player
                                        autoplay
                                        loop
                                        src={cardAnimation}
                                    >
                                    </Player>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={styles.divWithBackground} style={{
                            backgroundImage: `url(${cardLogo})`,
                        }}>
                            <Button variant="success" data-testid="pay" onClick={startPaymentProcess}>Pay ₨ 99</Button>
                        </div>
                }
                {
                    verifyOrderProcessingCompleted && <Redirect to={`/chat/${doctorId}`}/>
                }
                {
                    paymentSuccess === false &&
                    <div data-testid="errorInPayment">Error has happened contact Support</div>
                }
                {
                    verifyOrderProcessingCompleted === false &&
                    <h1 data-testid="settingUpChat">Payment processing Done Setting Up Chat</h1>
                }
            </>
            :
            <div className={styles.divWithBackground} style={{
                backgroundImage: `url(${loginSvg})`
            }}>
                {!user && <Button
                    disabled={userRequestInProgress}
                    onClick={login}
                    data-testid="loginViaGoogle">Login through Google</Button>}
            </div>
        }
    </>
}
