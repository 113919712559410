import { useEffect, useState} from "react";
import {SignUpDoctorRequest} from "../DoctorSignUp/SignUpAsDoctor";
import {getListOfAllDoctors} from "../../services/listOfAllDoctors";
import {DoctorInfoCard} from "../../components/doctorInfo/DoctorInfoCard";
import DoctorInfoPlaceholder from "../../components/doctorInfo/DoctorInfoPlaceHolder";

export function AllDoctors() {

    const [allDoctors, setAllDoctors] = useState<SignUpDoctorRequest[]>([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false)
        getListOfAllDoctors().then((doc) => {
            setAllDoctors(doc)
            setLoaded(true)
        })
    }, [])
    return <div style={{
        margin: "0.5em",
    }}>
        <h3>Choose a doctor to chat with</h3>
        {!loaded && <DoctorInfoPlaceholder/>}
        {allDoctors.map((value, index) => {
            return <DoctorInfoCard key={index} value={value}/>
        })}
    </div>
}
