import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import doctor from './doctor.svg'
import styles from './style.module.css'

export function LandingPage() {

    return <>
        <div style={{
            backgroundImage: `url(${doctor})`,
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            height: "100%",
            display: "flex",
            alignItems: "flex-end"
        }}>
            <div className={styles.description}>
                <h3>Welcome to Doctor Chat Me</h3>
                <p>Our app helps you to talk to your doctor via messages in app for a small fee. You can talk to our
                    doctors for as little fee as ₹99 for upto two weeks</p>
                <div className={styles.buttons}>
                    <Link to="/all-doctors">
                        <Button className={styles.buttonStyleLandingPage}
                                data-testid="lookForDoctors"
                                variant="primary">Find a doctor
                        </Button>
                    </Link>
                    <Link to={"/login"}>
                        <Button
                            className={styles.buttonStyleLandingPage}
                            data-testid="lookForDoctors"
                            variant="secondary"
                        >
                            Already a member? Login
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    </>
}
