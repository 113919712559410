import Button from "react-bootstrap/Button";
import {useState} from "react";

export type PromisedButtonProps = {
    onClick: () => Promise<void>,
    text: string,
    dataTestId?: string,
}

export function PromisedButton(
    {onClick, text, dataTestId}: PromisedButtonProps) {

    const [disabled, setDisabled] = useState(false)

    function onClickRerouted() {

        if (disabled) {
            return;
        }

        setDisabled(true)
        onClick().then(() => {
            setDisabled(false)
        })
    }

    return <Button
        data-testid={dataTestId}
        onClick={onClickRerouted}
        variant={disabled ? "dark" : "primary"}
    >{text}</Button>;
}
