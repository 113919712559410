import {
    getDatabase,
    Database,
    ref,
    set,
    push,
    onValue,
    DataSnapshot,
    onChildAdded,
    connectDatabaseEmulator
} from 'firebase/database';
import {initializeApp} from 'firebase/app';
import {Chat} from "../components/chat/ChatHistoryUI";
import {
    getAuth,
    GoogleAuthProvider,
    Auth,
    connectAuthEmulator,
    onAuthStateChanged,
    signOut,
    User,
} from 'firebase/auth';
import {getFunctions, Functions, httpsCallable, HttpsCallable, connectFunctionsEmulator} from 'firebase/functions';
import {signInWithPopup, signInWithRedirect} from "@firebase/auth";
import {getAnalytics, logEvent, Analytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};


export type GenericMessageListener = (message: DataSnapshot) => void;

class FirebaseInterface {
    database: Database;
    provider: GoogleAuthProvider
    auth: Auth
    functions: Functions
    analytics: Analytics | undefined

    constructor() {
        let app = initializeApp(firebaseConfig);
        this.provider = new GoogleAuthProvider();
        this.auth = getAuth(app);
        this.database = getDatabase(app);
        this.functions = getFunctions(app);
        this.analytics = undefined;

        if (process.env.NODE_ENV !== "production") {

            // eslint-disable-next-line no-restricted-globals
            const hostname = location.hostname;
            connectAuthEmulator(this.auth, `http://${hostname}:9099`, {
                disableWarnings: true
            })
            connectFunctionsEmulator(this.functions, hostname, 5001)
            connectDatabaseEmulator(this.database, hostname, 9000)
        }
        else {
            this.analytics = getAnalytics(app);
        }
    }

    writeUserData(message: Chat, key: string) {
        let messageRef = ref(this.database, key);
        const newMessageRef = push(messageRef);

        set(newMessageRef, {
            ...message,
            timeStamp: message.timeStamp.toISOString()
        })
    }

    registerForNewItems<T>(messageListener: (message: T) => void, key: string) {
        return onChildAdded(
            ref(this.database, key),
            snapshot => {
                messageListener(snapshot.val() as T);
            },
        );
    }

    readArrayOnce<T>(messageListener: (message: T[]) => void, key: string) {
        return onValue(
            ref(this.database, key),
            snapshot => {
                const items : T[] = []
                snapshot.forEach(childSnapShot => {
                    items.push(childSnapShot.val())
                })

                messageListener(items);
            }, {
                onlyOnce: true
            }
        );
    }

    redirectForLogin() {

        if (process.env.NODE_ENV !== "production") {

            console.log("redirecting now")
            signInWithRedirect(this.auth, this.provider);
            return;
        }

        signInWithPopup(this.auth, this.provider)
            .then(() => {
                console.log("Success Logging in")
            })
            .catch(error => {
                console.log("An error occured");
                console.log(error);
            })
    }

    logOff() {
        return signOut(this.auth)
    }

    httpsCallableFunction<
        RequestData = unknown,
        ResponseData = unknown
        >(
            functionName:
                'createOrder' |
                'verifyOrder' |
                'signUp'
    ): HttpsCallable<RequestData, ResponseData> {
        return httpsCallable(this.functions, functionName)
    }

    onAuthChanged(authChanged : (user : User | null) => void) {

        return onAuthStateChanged(this.auth, (user) => {
            authChanged(user)
        })
    }

    logUserSuccessfulAuthentication() {

        if (!this.analytics) {
            return;
        }

        return logEvent(this.analytics, 'login')
    }

    logPaymentInitiated() {
        if (!this.analytics) {
            return;
        }

        return logEvent(this.analytics, 'begin_checkout');
    }

    logPaymentSuccess() {
        if (!this.analytics) {
            return;
        }

        return logEvent(this.analytics, 'payment_success');
    }

    logPageView(eventParams?: {
        page_title?: string;
        page_location?: string;
        page_path?: string;
        [key: string]: any;
    }) {

        if (!this.analytics) {
            console.log("page_view", eventParams);
            return ;
        }

        return logEvent(this.analytics, 'page_view', eventParams);
    }

    log(eventName: string) {

        if (!this.analytics) {
            console.log(eventName);
            return ;
        }

        return logEvent(this.analytics, eventName)
    }

    logOrderVerifySuccess() {
        if (!this.analytics) {
            return ;
        }
        return logEvent(this.analytics, 'order_verify_success')
    }
}

export default new FirebaseInterface();
