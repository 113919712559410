import {SignUpDoctorRequest} from "../../views/DoctorSignUp/SignUpAsDoctor";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import doctorIcon from "../../views/ListOfDoctors/doctor-kid.svg";

import bindhiya from "./Dr.Bindhiya.jpg"
import sandeep from "./drsandeep.jpg"
import resen from "./drresan.jpeg"

type ImageMap = {
    name: string,
    image: string
}

const images : ImageMap[] = [{
    name: "sandeep",
    image: sandeep
}, {
    name: "bindhiya",
    image: bindhiya
}, {
    name: "resen",
    image: resen
}]

export function DoctorInfoCard(props: { value: SignUpDoctorRequest }) {
    return <Card>
        <Card.Img variant="top" src={getImage(props.value.name)}/>
        <Card.Body>
            <Card.Title>{props.value.name}</Card.Title>
            <Card.Text>
                <p>Place of work: {props.value.placeOfWork}</p>
                <p>{props.value.description}</p>
            </Card.Text>
            <Link to={`/hello/${props.value.userId}`}>
                <Button variant="primary" data-testid={`talkToDoctor_${props.value.name}`}>Chat
                    with {props.value.name}</Button>
            </Link>
        </Card.Body>
    </Card>;
}


function getImage(name: string) {

    const search = images.find(image=> name.toLocaleLowerCase().includes(image.name));

    if (search) {
        return search.image;
    }

    return doctorIcon;
}
