import {DoctorInfoCard} from "../../components/doctorInfo/DoctorInfoCard";
import {useEffect, useState} from "react";
import {SignUpDoctorRequest} from "../DoctorSignUp/SignUpAsDoctor";
import {getListOfAllDoctors} from "../../services/listOfAllDoctors";
import {useParams} from "react-router-dom";
import DoctorInfoPlaceholder from "../../components/doctorInfo/DoctorInfoPlaceHolder";
import firebaseInterface from "../../hailJesus/FirebaseInterface"

export function TalkToDoctor() {
    const [doctorToTalkTo, setDoctorToTalkTo] = useState<SignUpDoctorRequest | undefined>(undefined);

    const [loading, setLoading] = useState(true);
    const {doctor} = useParams<{ doctor: string }>();

    useEffect(() => {
        firebaseInterface.logPageView({
            page_title: "Talk to individual doctor",
            page_location: doctor,
            page_path: "talkto/" + doctor
        });
        setLoading(true);
        getListOfAllDoctors()
            .then((doc) => {
                setLoading(false);
                setDoctorToTalkTo(
                    doc.find(
                        s=> s.name
                            .replaceAll(" ", "")
                            .replaceAll(".", "")
                            .toLocaleLowerCase().includes(
                                doctor.toLocaleLowerCase()
                                .replaceAll("dr", "")
                                .replaceAll("doctor", "")
                            )));
        })
    }, [doctor])

    return <>
        {
        <div style ={{
            margin: "0.5em",
            display: "flex",
            alignItems: "flex-start",
            height: "100%",
        }}>

            <div style={{marginBottom: "5em"}}>
                <h1>Welcome to doctor chat app</h1>
                <p>We help you to text our doctors through our web app for a small fee, you can get
                    a second opinion or follow up questions that you may have after consultation.</p>
                <p>Once you pay the fee you can speak with our doctors here for as long as <b><i>two weeks</i></b> which
                    should give you ample amount of time to ask all your questions</p>
                <p>You can talk to {displayName(doctorToTalkTo)} for <b><i>₹99</i></b></p>
                <h2 data-testid = "talkToDrTitle">Talk to {displayName(doctorToTalkTo)}</h2>
                {loading ? <DoctorInfoPlaceholder /> : doctorToTalkTo ?
                    <DoctorInfoCard value={doctorToTalkTo} /> : "Unable load the doctor details please try again"}
            </div>
        </div>
        }
    </>
}


function displayName(doctor: SignUpDoctorRequest | undefined) {
    return doctor ? doctor.name.toLocaleLowerCase().includes("dr") ? doctor.name : "Dr." + doctor.name : "doctor";
}
