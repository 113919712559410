import {useEffect, useState} from "react";
import firebaseInterface from "../../hailJesus/FirebaseInterface"
import {Link} from "react-router-dom";
import {User} from "../../modal/User";
import {Alert, Badge, ListGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import noCards from "./noChat.svg"
import styles from "./style.module.css"

export type MessageUserInfo = {
    name: string,
    userId: string
}
export type ChatOverviewProps = {
    currentUser: User
}

export function ChatOverView({currentUser}: ChatOverviewProps) {

    const [messageInfos, setMessageInfos] = useState<MessageUserInfo[] | undefined>(undefined)

    useEffect(() => {
        setMessageInfos(undefined);
        return firebaseInterface.readArrayOnce((messageUserInfo: MessageUserInfo[]) => {
                setMessageInfos(messageUserInfo)
            },
            `userConnectionInfo/${currentUser.userId}/canTalkTo`
        )
    }, [currentUser])

    return <>
        <div data-testid="chatOverView" style={{margin: "0.5em"}}>
            <h3>Welcome, {currentUser.displayName}</h3>

            {messageInfos && messageInfos.length > 0 ?
                <ChatList messageInfos={messageInfos}/>
                :
                messageInfos && messageInfos.length === 0 && <NoChatAvailable/>
            }

            {messageInfos === undefined && <div data-testid="loadingFeedback">

            </div>}

            <div>
                <Link to="/all-doctors">
                    <Button data-testid="lookForDoctors"
                            variant="primary"
                            style={{margin: "1em 0 0 0"}}
                    >
                        Find doctors to chat
                    </Button>
                </Link>
            </div>
        </div>
    </>
}

function ChatList({messageInfos}: { messageInfos: MessageUserInfo[] }) {

    return <>
        <ListGroup as="ul" style={{marginTop: "2em"}}>
            {messageInfos.map(({name, userId}, index) => {
                return <ListGroup.Item
                    as="li"
                    key={index}
                >
                    <Link to={`/chat/${userId}`}>
                        <div className="d-flex justify-content-between align-items-start">
                            <div data-testid="chatDisplayName" className="ms-2 me-auto">
                                <div className="fw-bold">{name}</div>
                            </div>
                            <Badge bg="primary" pill>
                                &gt;
                            </Badge>
                        </div>
                    </Link>
                </ListGroup.Item>
            })}
        </ListGroup>
        <Alert variant="info" style={{marginTop: "2em"}}>
            Above are the list of doctors you can chat with for upto 2 weeks
        </Alert>
    </>
}

function NoChatAvailable() {
    return <div className={styles.noChat} data-testid="noChatsAvailable">
        <img alt="noChatAvailable" src={noCards}/>
        <h5>
            No Chats Available
        </h5>
    </div>;
}
