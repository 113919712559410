
import firebaseInterface from "../../../hailJesus/FirebaseInterface"
import {useState} from "react";

export function ForceLogOff() {

    const [message, setMessage] = useState("")

    firebaseInterface
        .logOff().then(() => {
            setMessage("Log Off Complete")
    })

    return <span>{message}</span>;
}
