import {Link} from "react-router-dom";

import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {SignUpDoctorRequest} from "../DoctorSignUp/SignUpAsDoctor";
import {findDoctorWithId} from "../../services/listOfAllDoctors";
import Button from "react-bootstrap/Button";
import loginSvg from "./Login.svg"
import firebaseInterface from "../../hailJesus/FirebaseInterface";

export function HelloDoctor() {

    const {doctorId} = useParams<{ doctorId: string }>();

    const [doctor, setDoctor] = useState<SignUpDoctorRequest | undefined>(undefined);

    useEffect(() => {
        firebaseInterface.logPageView({
            page_title: "Hello Doctor, Login and Pay",
            page_location: doctorId,
            page_path: "hello/" + doctorId
        });
        findDoctorWithId(doctorId).then((doc)=> {
            setDoctor(doc)
        })
    }, [doctorId])

    return <>
        {!!doctor &&
        <div style ={{
            margin: "0.5em",
            display: "flex",
            alignItems: "flex-end",
            height: "100%",
            backgroundImage: `url(${loginSvg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundAttachment: "fixed",
            backgroundPosition: "center"
        }}>
            <div style={{marginBottom: "5em"}}>
                <h1>Talk to {doctor.name}</h1>
                <p>Inorder to speak to {doctor.name} please login with your, google mail and pay ₹99</p>
                <Link to={`/pay/${doctor.userId}`}>
                    <Button data-testid="loginAndPay" variant="primary">Login and pay 99₹</Button>
                </Link>
            </div>
        </div>
        }
    </>
}
