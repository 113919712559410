
import "./ChatHistoryUI.css"

export type Chat = {
    messageId: string,
    senderId: string,
    displayName: string,
    message: string,
    timeStamp: Date
}

export type ChatHistoryUIProps = {
    messages: Chat[],
    currentUserId: string,
}
/**
 * TODO:
 * Style like this:
 *
 https://codepen.io/zenorocha/pen/eZxYOK
 * */
export function ChatHistoryUI ({messages, currentUserId} : ChatHistoryUIProps) {

    return <div className="chatHistoryWrapper" data-testid="message_window">
        {
            messages && messages.length === 0 &&
            <div data-testid="firstMessage">
                Please send your first message.
            </div>
        }
        {
            messages.map((message, index) => {
                return <div
                    data-testid = {`message_${message.senderId}`} key={index}
                    className={message.senderId === currentUserId ? "currentUser" : "otherUser"}
                >{message.message}
                    <div data-testid="time">{formatTime(message.timeStamp)}</div>
                </div>
            })
        }
    </div>
}

function formatTime(date: Date) {
    return zeroPad(date.getHours()) + ":" + zeroPad(date.getMinutes())
}

function zeroPad(number: number) {
    return number < 10 ? `0${number}` : number
}
