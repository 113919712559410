
import firebaseInterface from "../hailJesus/FirebaseInterface";
import {SignUpDoctorRequest} from "../views/DoctorSignUp/SignUpAsDoctor";

let allDoctors: SignUpDoctorRequest[] = []

export async function getListOfAllDoctors() : Promise<SignUpDoctorRequest[]> {

    if (allDoctors.length > 0) {
        return Promise.resolve(allDoctors);
    }

    return new Promise((resolve => {
        firebaseInterface.readArrayOnce<SignUpDoctorRequest>((msg) => {
            allDoctors = msg;
            resolve(allDoctors);
        }, "registeredDoctors")
    }))
}

export async function findDoctorWithId(userId: string) : Promise<SignUpDoctorRequest | undefined> {

    return new Promise((resolve) => {
        getListOfAllDoctors().then(docs => {
            resolve(docs.find((doc) => doc.userId === userId));
        })
    })
}
