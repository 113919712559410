import React, {useEffect, useState} from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect,
} from "react-router-dom";
import {User} from "./modal/User";
import FindDoctor from "./views/FindDoctor/FindDoctor";
import {ChatOverView} from "./views/Chat/ChatOverview";
import {ChatView} from "./views/Chat/ChatView";
import {LandingPage} from "./views/LandingPage/LandingPage";
import {AllDoctors} from "./views/ListOfDoctors/AllDoctors";
import {HelloDoctor} from "./views/HelloDoctor/HelloDoctor";
import {LoginAndPay} from "./views/LoginAndPay/LoginAndPay";
import {SignUpAsDoctor} from "./views/DoctorSignUp/SignUpAsDoctor";
import { Login } from './views/Login/Login';
import firebaseInterface from "./hailJesus/FirebaseInterface"
import {ForceLogOff} from "./views/TestSupport/ForceLogOff/ForceLogOff";
import {TalkToDoctor} from "./views/DrSandeepSamson/TalkToDoctor";

function App() {
    const [user, setUser] = useState<User | undefined>(undefined);

    useEffect(() => {
        return firebaseInterface.onAuthChanged((user)=> {

            if (user) {
                console.log("User found, setting user")
                setUser({
                    userId: user.uid,
                    displayName: user.displayName!!
                })
            }
            else {
                console.log("User has logged out")
                setUser(undefined)
            }
        })
    }, [])

    return (
        <div style={{height: "100vh"}}>
            <Router>
                <Switch>
                    <Route path={"/signupAsADoctor"}>
                        <SignUpAsDoctor />
                    </Route>
                    <Route path="/find-doctor">
                        <FindDoctor/>
                    </Route>
                    <Route path="/chatOverview" key="chatOverView">
                        <>
                            {user ? <ChatOverView currentUser={user}/> : "Please login"}
                        </>
                    </Route>
                    <Route path="/chat/:to" key="chat">
                        {user && <ChatView currentUser={user}/>}
                    </Route>
                    <Route path={"/pay/:doctorId"}>
                        <LoginAndPay />
                    </Route>
                    <Route path={"/hello/:doctorId"}>
                        <HelloDoctor />
                    </Route>
                    <Route path={"/login"}>
                        <Login />
                    </Route>
                    <Route path="/all-doctors">
                        <AllDoctors />
                    </Route>
                    <Route path={"/forceLogOff"}>
                        <ForceLogOff />
                    </Route>
                    <Route path={"/talkto/:doctor"}>
                        <TalkToDoctor />
                    </Route>
                    <Route path="/">
                        {user ? <Redirect to="/chatOverview"/> : <LandingPage />}
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
