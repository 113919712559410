import styles from "../LoginAndPay/style.module.css";
import loginSvg from "../HelloDoctor/Login.svg";
import Button from "react-bootstrap/Button";
import firebaseInterface from "../../hailJesus/FirebaseInterface";
import {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";

/**
 * Refer this for better auth flow:
 * https://v5.reactrouter.com/web/example/auth-workflow
 * */
export function Login() {

    const [loggingSuccess, setLoggingSuccess] = useState(false);

    useEffect(() => {

        return firebaseInterface.onAuthChanged((user) => {
            if (user) {
                setLoggingSuccess(true)
            }
        });
    }, [])

    return <>
        {loggingSuccess && <Redirect to={"/"} />}
        <div className={styles.divWithBackground} style={{
            backgroundImage: `url(${loginSvg})`
        }}>
            <Button onClick={login} data-testid="loginViaGoogle">Login through Google</Button>
        </div>
    </>
}

function login() {
    firebaseInterface.redirectForLogin()
}
