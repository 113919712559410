import {ChatHistoryUI, ChatHistoryUIProps} from "./ChatHistoryUI";
import {useState} from "react";
import {uuid} from "../../services/uuid";
import { AiOutlineSend } from 'react-icons/ai';
import Button from "react-bootstrap/Button";

export type ChatUIProps = ChatHistoryUIProps & {
    onNewMessage: (message: string, messageId: string) => void
}

export function ChatUI({messages, currentUserId, onNewMessage}: ChatUIProps) {

    const [message, setMessage] = useState("");

    function notify() {
        if (message) {
            onNewMessage(message, uuid())
            resetMessageInput();
        }
    }

    function resetMessageInput() {
        setMessage("")
    }

    return <>
        <ChatHistoryUI messages={messages} currentUserId={currentUserId}/>
        <div>
            <div style={{display: "flex", height: "5%", width: "100%", position: "fixed", bottom: "0", left: "0"}}>
                <input placeholder="Type your message."
                       type="text"
                       data-testid="messageInput"
                       onChange={(e) => {
                           setMessage(e.target.value)}
                       }
                       value={message}
                       style={{
                           width: "88%"
                       }}
                />
                <Button data-testid="sendButton" onClick={notify} style={{
                    width: "11%",
                    marginLeft: "0.5%"
                }}><AiOutlineSend /></Button>
            </div>
        </div>
    </>
}
