import firebaseInterface from "./FirebaseInterface";
import companyLogo from "./companyLogo.png";

export type RazorPayCreatedOrder = {
    amount: number,
    id: string,
    status: "created" | string
}

export type RazorPayCreateOrder = {
    amount: number,
}

export type VerifyOrderRequest = {
    razorpayPaymentId: string,
    razorpayOrderId: string,
    razorpaySignature: string,
    patientId: string,
    patientName: string,
    doctorId: string,
    doctorName: string
}

export function pay(
    amount : number,
    successHandler: (response : RazorPaySuccessHandlerResponse) => void,
    failureHandler: (response: PaymentErrorResponse) => void,
    onDismiss: () => void
    ) {

    firebaseInterface
        .httpsCallableFunction<
            RazorPayCreateOrder,
            RazorPayCreatedOrder
            >('createOrder')
        ({amount: amount})
        .then((response) => {

            const razorPay = new Razorpay({
                amount: amount,
                description: "",
                handler: successHandler,
                image: companyLogo,
                key: process.env.REACT_APP_RAZOR_PAY_KEY!!,
                name: "Doctor Chat Web App",
                notes: {
                    address: "notes"
                },
                order_id: response.data.id,
                prefill: {
                },
                theme: {
                    color: "#3399cc"
                },
                modal: {
                    escape: false,
                    ondismiss: () => {
                        onDismiss();
                    }
                }
            });

            razorPay.on('payment.failed', failureHandler)

            razorPay.open()
        })
        .catch(() => {
            failureHandler({
                code: "",
                description: "error whilst creating order",
                metadata: {order_id: "", payment_id: ""},
                reason: "",
                source: "",
                step: ""
            });
        });
}

export async function verify(data: VerifyOrderRequest) : Promise<void> {
    return firebaseInterface.httpsCallableFunction<VerifyOrderRequest>('verifyOrder')(data)
        .then(() => Promise.resolve()).catch(() => Promise.reject())
}
