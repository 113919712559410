import {useEffect, useState} from "react";
import {User} from "../../modal/User";
import firebaseInterface from "../../hailJesus/FirebaseInterface";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {PromisedButton} from "../../components/promisedButton/PromisedButton";

export type SignUpDoctorRequest = {
    name: string,
    userId: string,
    placeOfWork: string,
    description: string
}

export function SignUpAsDoctor() {

    const [userFetchProcessing, setUserFetchProcessing] = useState(false)

    const [user, setUser] = useState<User | undefined>(undefined);
    const [registered, setRegistered] = useState(false);

    function login() {
        firebaseInterface.redirectForLogin()
    }

    useEffect(() => {
        setUserFetchProcessing(true)
        firebaseInterface.onAuthChanged((user) => {
            setUserFetchProcessing(false)
            if (user) {
                setUser({
                    userId: user.uid,
                    displayName: user.displayName!!
                })
            } else {
                setUser(undefined)
            }
        })
    }, [])

    function submitSuccess(isLoading: boolean) {
        setRegistered(isLoading);
    }

    return <>
        <div>
            {(!user) ? <Button
                disabled={userFetchProcessing}
                data-testid="loginViaGoogle"
                onClick={login}
                variant={userFetchProcessing ? "dark" : "primary"}
            >Login through Google</Button> : ""}
            {
                (!!user && registered) ? "Successfully Registered" :
                    !!user && !registered && <SignUpDoctor submitSuccess={submitSuccess} user={user}/>
            }
        </div>
    </>
}


function SignUpDoctor({submitSuccess, user}: { submitSuccess: (isLoading: boolean) => void, user?: User }) {

    const [placeOfWork, setPlaceOfWork] = useState("")
    const [description, setDescription] = useState("")

    function signUp() {
        return new Promise<void>((resolve) => {
            submitSuccess(false)
            if (user) {
                firebaseInterface.httpsCallableFunction<SignUpDoctorRequest>('signUp')({
                    name: user.displayName,
                    userId: user.userId,
                    placeOfWork: placeOfWork,
                    description: description,
                }).then(() => {
                    submitSuccess(true)
                    resolve()
                })
            }
        })
    }

    return <div style={{margin: "0.5em"}}>
        <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Which Hospital do you Work at ?</Form.Label>
                <Form.Control type="text" value={placeOfWork}
                              onChange={(e) => {
                                  setPlaceOfWork(e.target.value)
                              }}
                              placeholder="Example: Manipal, KIMS etc"
                              data-testid={"placeOfWork"}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Description about you</Form.Label>
                <Form.Control as="textarea" rows={3}
                              value={description}
                              onChange={(e) => {
                                  setDescription(e.target.value)
                              }}
                              data-testid="description"
                              placeholder="Give Description as a third person, for example, Dr House works at Princeton Hospital, with Expertise in Diagnostics"/>
            </Form.Group>
        </Form>

        <PromisedButton onClick={signUp} text={"Signup as a doctor"} />
    </div>;
}
