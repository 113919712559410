import {ChatUI} from "../../components/chat/ChatUI";
import firebaseInterface from "../../hailJesus/FirebaseInterface"
import {Link} from "react-router-dom";
import {IoMdArrowRoundBack} from 'react-icons/io'

import {useEffect, useState} from "react";
import {Chat} from "../../components/chat/ChatHistoryUI";
import {User} from "../../modal/User";
import {now} from "../../services/currentDate";
import {useParams} from "react-router-dom";
import {getConversationKeyFromUserIds} from "../../services/keyService";
import {SignUpDoctorRequest} from "../DoctorSignUp/SignUpAsDoctor";
import {findDoctorWithId} from "../../services/listOfAllDoctors";
import Button from "react-bootstrap/Button";


export type ChatViewProps = {
    currentUser: User
}

export function ChatView({currentUser}: ChatViewProps) {

    const [message, setMessage] = useState<Chat[]>([])

    const {to} = useParams<{ to: string }>();

    const [doctor, setDoctor] = useState<SignUpDoctorRequest | undefined>();

    const messageListener = (firebaseMessage: Chat) => {
        firebaseMessage.timeStamp = new Date(firebaseMessage.timeStamp);
        setMessage((prevState => unique(prevState, firebaseMessage)))
    }

    const sendMessageToFireBase = (message: string, id: string) => {
        let firebaseMessageChat: Chat = {
            messageId: id,
            message: message,
            timeStamp: now(),
            senderId: currentUser.userId,
            displayName: currentUser.displayName
        };
        messageListener(firebaseMessageChat)
        let dbKey = getConversationKeyFromUserIds(currentUser.userId, to);
        firebaseInterface.writeUserData(firebaseMessageChat, dbKey)
    }

    useEffect(() => {

        findDoctorWithId(to).then((doc) => {
            setDoctor(doc);
        })

        return firebaseInterface.registerForNewItems(messageListener,
            getConversationKeyFromUserIds(currentUser.userId, to)
        )
    }, [currentUser, to])

    return <>
        <div style={{display: "flex"}}>
            <Link to="/chatOverView"><Button variant="primary"><IoMdArrowRoundBack /></Button></Link><h3>{doctor?.name}</h3>
        </div>
        <ChatUI messages={message} currentUserId={currentUser.userId} onNewMessage={(msg, id) => {
            sendMessageToFireBase(msg, id)
        }}/>
    </>
}


function unique(prevState: Chat[], firebaseMessage: Chat): Chat[] {

    const existingMessageIds = new Set<string>();

    return [...prevState, firebaseMessage].filter(message => {

        if (existingMessageIds.has(message.messageId)) {
            return false;
        }

        existingMessageIds.add(message.messageId);
        return true;
    });
}
